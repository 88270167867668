import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FaEnvelope, FaEye } from "react-icons/fa6";
import * as yup from "yup";

const credChangeSchema = yup.object().shape({
    email: yup.string().email().required("Email is required"),
    oldPassword: yup.string().required("Password is required"),
    newPassword: yup.string().required("Password is required"),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});

const ChangeCredsTab = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(credChangeSchema, {
            defaultValues: {
                email: "",
                oldPassword: "",
                newPassword: "",
            },
        }),
    });

    const handleSaveClick = (data) => {
        data.preventDefault();
        console.log(data);
    };

    return (
        <form className="change-creds-tab">
            <div className="h-50">
                <Row>
                    <Col xs={12} md={12} className="change-creds-tab-header">
                        <h3>Change Email</h3>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4}>
                        <div className="">
                            <label>Current Email:</label>
                            <p className="current-email">
                                krunal.laughlogiclabs@gmail.com
                            </p>
                        </div>
                    </Col>
                    <Col xs={12} md={4}>
                        <div className="">
                            <label>New Email:</label>
                            <CredInput
                                {...register("email")}
                                name="email"
                                type="email"
                                placeholder="Email"
                                icon={
                                    <FaEnvelope
                                        size={20}
                                        error={errors.email?.message}
                                    />
                                }
                            />
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="h-50 d-flex flex-column justify-content-between">
                <Row>
                    <Col xs={12} md={12} className="change-creds-tab-header">
                        <h3>Change Password</h3>
                    </Col>
                    <Col xs={12} md={4}>
                        <CredInput
                            {...register("oldPassword")}
                            name="oldPassword"
                            type="password"
                            placeholder="Old Password"
                            icon={
                                <FaEye
                                    size={20}
                                    cursor="pointer"
                                    error={errors.oldPassword?.message}
                                />
                            }
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <CredInput
                            {...register("newPassword")}
                            name="newPassword"
                            type="password"
                            placeholder="New Password"
                            icon={
                                <FaEye
                                    size={20}
                                    cursor="pointer"
                                    error={errors.newPassword?.message}
                                />
                            }
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <CredInput
                            {...register("confirmPassword")}
                            name="confirmPassword"
                            type="password"
                            placeholder="Confirm Password"
                            icon={
                                <FaEye
                                    size={20}
                                    cursor="pointer"
                                    error={errors.confirmPassword?.message}
                                />
                            }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12}>
                        <button
                            className="add-card-readers-btn"
                            type="submit"
                            onClick={handleSubmit(handleSaveClick)}
                        >
                            Save
                        </button>
                    </Col>
                </Row>
            </div>
        </form>
    );
};

export default ChangeCredsTab;

const CredInput = ({ icon, error, ...rest }) => {
    return (
        <>
            <div className="creds-input">
                <input {...rest} />
                {icon && icon}
            </div>
            {error && (
                <p
                    className="text-danger"
                    style={{ fontSize: "12px", margin: "5px 0 0 5px" }}
                >
                    {error}
                </p>
            )}{" "}
        </>
    );
};
