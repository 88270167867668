import React from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import FrizdaMap from '../FrizdaMap';
import Login from '../authentication_UI/Login';
import PostAuth from '../authentication_UI/PostAuth';
import SignUp from '../authentication_UI/SignUp';
import AccountDashboard from '../frizda_business/AccountDashboard';
import AddClub from '../frizda_business/AddClub';
import SignUpForBusiness from '../frizda_business/SignUpForBusiness';
import CardReaders from '../frizda_business/add-nightclub-requirements/CardReaders';
import NightclubDetails from '../frizda_business/add-nightclub-requirements/NightclubDetails';
import BusinessRoutes from '../frizda_business/business-router/BusinessRouter';
import CheckEmail from '../frizda_business/businessStates&Auth/auth-components/CheckEmail';
import ForgotPassword from '../frizda_business/businessStates&Auth/auth-components/ForgotPassword';
import LogIn from '../frizda_business/businessStates&Auth/auth-components/LogIn';
import ResetPassword from '../frizda_business/businessStates&Auth/auth-components/ResetPassword';
import VerifyEmail from "../frizda_business/businessStates&Auth/auth-components/VerifyEmail";
import FrizdaDashboard from '../frizda_business/dashboard/FrizdaDashboard';
import AffiliateMarketing from '../frizda_business/dashboard/affiliate-marketing/AffiliateMarketing';
import ClientData from '../frizda_business/dashboard/client-data/ClientData';
import FrizdaAds from '../frizda_business/dashboard/frizda-ads/FrizdaAds';
import MoneticsHome from '../frizda_business/dashboard/monetics/MoneticsHome';
import NightspotDetails from '../frizda_business/dashboard/nightspot-details/NightspotDetails';
import CreateTicket from '../frizda_business/dashboard/tickets/CreateTicket';
import { TicketDetails } from '../frizda_business/dashboard/tickets/TicketDetails';
import Tickets from '../frizda_business/dashboard/tickets/Tickets';
import TicketsHome from '../frizda_business/dashboard/tickets/TicketsHome';
import LandingPage from '../frizda_business/landing-page/LandingPage';
import About from '../frizda_business/navbar-links/BusinessAbout';
import FrizdaCoins from '../frizda_business/navbar-links/FrizdaCoins';
import PlanPage from '../frizda_business/plan-page';
import BusinessPlans from '../frizda_business/plans for business/BusinessPlans';
import ServiceHomePage from '../frizda_business/service-home-page';
import ProtectRoutes from './ProtectRoutes';
import AffiliateMarketingWrapper from '../frizda_business/dashboard/affiliate-marketing/AffiliateMarketingWrapper';
import AffiliateDetails from '../frizda_business/dashboard/affiliate-marketing/AffiliateDetails';
import SettingsWrapper from '../frizda_business/dashboard/settings/SettingsWrapper';
import Homepage from '../frizda_business/dashboard/home/Homepage';


const AppRouter = () => {
    const routes = createBrowserRouter([
        {
            index: true,
            element: <Navigate to="/business" />,
        },
        {
            path: '/map',
            element: <FrizdaMap />,
        },
        {
            path: '/signup',
            element: <SignUp />,
        },
        {
            path: '/login',
            element: <Login />,
        },
        {
            path: '/auth',
            element: <PostAuth />,
        },
        {
            path: '/business',
            element: <BusinessRoutes />,
            children: [
                {
                    index: true,
                    element: <LandingPage />,
                },
                {
                    path: 'account',
                    element: <AccountDashboard />,
                },
                {
                    path: 'frizda-coins',
                    element: <FrizdaCoins />,
                },
                {
                    path: 'about',
                    element: <About />,
                },
                {
                    path: 'add',
                    element: <AddClub />,
                },
                {
                    path: 'signup',
                    element: <SignUpForBusiness />,
                },
                {
                    path: 'add/nightclub-details',
                    element: <NightclubDetails />,
                },
                {
                    path: 'add/plans',
                    element: <BusinessPlans />,
                },
                {
                    path: 'add/card-readers',
                    element: <CardReaders />,
                },
                {
                    path: 'login',
                    element: <LogIn />,
                },
                {
                    path: 'forgot-password',
                    element: <ForgotPassword />,
                },
                {
                    path: 'reset-password',
                    element: <ResetPassword />,
                },
                {
                    path: 'check-email',
                    element: <CheckEmail />,
                },
                {
                    path: 'verify-email',
                    element: <VerifyEmail />,
                },
                {
                    path: 'plans',
                    element: <PlanPage />,
                },
                {
                    path: 'service-home',
                    element: <ServiceHomePage />,
                },
                {
                    path: 'dashboard',
                    element:
                        <ProtectRoutes>
                            <FrizdaDashboard />
                        </ProtectRoutes>
                    ,
                    children: [
                        {
                            index: true,
                            element: <Navigate to="home" />,
                        },
                        {
                            path: 'home',
                            element: <Homepage />,
                        },
                        {
                            path: 'frizda-ads',
                            element: <FrizdaAds />,
                        },
                        {
                            path: 'nightspot-details',
                            element: <NightspotDetails />,
                        },
                        {
                            path: 'monetics',
                            element: <MoneticsHome />,
                        },
                        {
                            path: 'client-data',
                            element: <ClientData />,
                        },
                        {
                            path: 'affiliate-marketing',
                            element: <AffiliateMarketingWrapper />,
                            children: [
                                {
                                    index: true,
                                    element: <AffiliateMarketing />,
                                },
                                {
                                    path: ':id',
                                    element: <AffiliateDetails />,
                                }
                            ]
                        },
                        {
                            path: 'tickets',
                            element: <Tickets />,
                            children: [
                                {
                                    index: true,
                                    element: <TicketsHome />,
                                },
                                {
                                    path: ':id',
                                    element: <TicketDetails />,
                                },
                                {
                                    path: 'create',
                                    element: <CreateTicket />,
                                },
                                {
                                    path: 'edit/:id',
                                    element: <CreateTicket />,
                                },
                            ],
                        },
                        {
                            path: 'settings',
                            element: <SettingsWrapper />,
                        }
                    ],
                },
            ],
        },
    ]);


    return <RouterProvider router={routes} />
}

export default AppRouter