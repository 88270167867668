import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { FaEnvelope, FaPhone } from "react-icons/fa6";
import navbarlogo from '../../assets/images/frizdaLogoBlack.png';
import { LoggedInContext } from '../businessStates&Auth/businessAuthState';
import './styles/Footer.css';
import { use } from 'i18next';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();

    const { name, loggedIn, accountId } = useContext(LoggedInContext)

    const [subscribed, setSubscribed] = useState(false);
    const [alreadySubscribed, setAlreadySubscribed] = useState(false);
    const [problemSubscribing, setProblemSubscribing] = useState(false);
    const [emailUsed, setEmailUsed] = useState(false);

    useEffect(() => {
        axios.get(`http://localhost:8000/getAccountInfo/${accountId}/subscribed_to_newsletter`)
            .then(res => {
                const response = Number(res.data.field);
                if (response == 1) { setAlreadySubscribed(true) } else if (response === 0) { setAlreadySubscribed(false) }
            }).catch(err => console.error(err));
    });

    const subscribeToNewsletter = async () => {
        setSubscribed(false); setProblemSubscribing(false); setEmailUsed(false);
        const email = document.getElementById('newsletterEmail').value;
        await axios.post('http://localhost:8000/business-newlsetter-add-email', { email: email, name: loggedIn ? name : 'Dear User' })
            .then(res => {
                if (res.status == 205) {
                    setSubscribed(true);
                    if (loggedIn) {
                        axios.put(`http://localhost:8000/updateOwnerAccount/${accountId}/subscribed_to_newsletter/${1}`)
                            .then(res => console.log(res)).catch(err => console.error(err));
                    }
                } else if (res.status == 409) {
                    setEmailUsed(true);
                } else { setProblemSubscribing(true); }
            })
            .catch(() => setProblemSubscribing(true));
    }

    return (
        <div className='footer-container'>
            <div className="footer-section footer-section-1">
                <img src={navbarlogo} alt="Frizda" style={{ width: "150px", height: "50px", objectFit: "contain" }} />
                <div className="footer-contact-container">
                    <input
                        type="email"
                        name="email"
                        placeholder={t('enter_your_email')}
                        disabled={subscribed || alreadySubscribed}
                    />
                    <button type="button" disabled={subscribed || alreadySubscribed} onClick={subscribeToNewsletter}>{t('subscribe')}</button>
                    {subscribed && <p className="text-success mt-2">{t('subscribed_succesfully')}</p>}
                    {alreadySubscribed && <p className="mt-2">{t('subscribed')}</p>}
                    {emailUsed && <p className="text-warning mt-2">{t('email_already_subscribed')}</p>}
                    {problemSubscribing && <p className="text-danger mt-2">{t('something_went_wrong')}</p>}
                </div>
            </div>
            <div className="footer-section footer-section-2">
                <p>{t('features')}</p>
                <ul>
                    <li>{t('frizda_coins')}</li>
                    <li>{t('frizda_ads_it')}</li>
                    <li>{t('affiliate_marketing')}</li>
                    <li>{t('data')}</li>
                </ul>
            </div>
            <div className="footer-section footer-section-3">
                <p>{t('services')}</p>
                <ul>
                    <li>{t('plans')}</li>
                    <li>{t('assistance')}</li>
                    <li>{t('frizda_team')}</li>
                    <li>{t('jobs')}</li>
                </ul>
            </div>
            <div className="footer-section footer-section-4">
                <p>{t('contact_info')}</p>
                <ul>
                    <li>455 West Orchard Street Kings</li>
                    <li>Mountain, NC 280867</li>
                </ul>
                <div className="footer-contact-option">
                    <a href="tel:+32476052008"><FaPhone />&nbsp;&nbsp;+32 (476) 05-20-08</a>
                    <a href="mailto:frizda.business@gmail.com"><FaEnvelope />&nbsp;&nbsp;frizda.business@gmail.com</a>
                </div>
            </div>
        </div>
    )
}

export default Footer