import { yupResolver } from '@hookform/resolvers/yup';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaRegTimesCircle } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import * as yup from "yup";
import { ticketDanceClubOption, ticketDiscoBallOption, ticketNightClubOption } from '../../../assets/images';
import SpinLoader from '../../../components/home-page/loaders/SpinLoader';
import { ApiGet, ApiPost, ApiPut } from '../../../utils/ApiData';
import { TicketBackground, TicketTheme } from '../../../utils/constants';
import PrimaryButton from '../components/PrimaryButton';
import TicketPreview from './TicketPreview';


const ticketSchema = yup.object().shape({
    name: yup.string().required('*Name is required'),
    description: yup.string().required('*Add a description'),
    benefits: yup.array()
        .of(yup.string().required('*Enter benefit'))
        .max(11, '*You can add a maximum of 12 benefits')
        .required('*Benefits are required'),
    eventDate: yup.date().required('*Event date is required'),
    theme: yup.string().required('*Theme is required'),
    background: yup.string().required('*Background image is required'),
    price: yup.number().required('*Enter price').test(
        'is-required-if-not-free',
        '*Enter price',
        function (value) {
            const { free } = this.parent;
            if (!free && (!value)) {
                return false;
            }
            return true;
        }
    ),
    limit: yup.number().required('*Enter limit').test(
        'is-required-if-not-free',
        '*Enter limit',
        function (value) {
            const { free } = this.parent;
            if (!free && (!value)) {
                return false;
            }
            return true;
        }
    ),
    sellingEndDate: yup.date().nullable().optional(),
    isActive: yup.boolean()
});

const CreateTicket = () => {
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        control,
        watch,
        trigger,
        setValue,
        getValues,
        reset,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(ticketSchema),
        defaultValues: {
            name: '',
            description: '',
            benefits: [],
            theme: '',
            background: '',
            price: 0,
            limit: 0,
            eventDate: '',
            sellingEndDate: '',
            isActive: true,
        },
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'benefits',
    });

    const formData = watch();
    const { id } = useParams();
    const toast = useRef(null);

    const [isLoading, setIsLoading] = useState(false);

    const handleSave = async () => {

        formData.price = Number(formData.price);
        formData.limit = Number(formData.limit);

        try {
            const ticketData = id ? await ApiPut(`ticket/update/${id}`, formData) : await ApiPost('ticket/create', formData);

            if (ticketData?.data) {
                reset(ticketData?.data);
            }
            showSuccess('success', id ? "Ticket updated successfully" : "Ticket created successfully");
        } catch (error) {
            showSuccess('danger', "Something went wrong");
            console.error(error);
        }
    }

    const getTicketByID = async () => {
        setIsLoading(true);
        try {
            const { data } = await ApiGet(`ticket/${id}`);
            if (data) {
                setValue('name', data?.name);
                setValue('description', data?.description);
                setValue('benefits', data?.benefits);
                setValue('theme', data?.theme);
                setValue('background', data?.background);
                setValue('price', data?.price);
                setValue('limit', data?.limit);
                setValue('eventDate', data?.eventDate);
                setValue('isActive', data?.isActive);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error(error);
        }
    }

    const showSuccess = (severity, message) => {
        toast.current.show({
            severity: severity,
            summary: (severity === 'success') ? 'Success' : 'Error',
            detail: message,
            life: 2000
        });
    }

    useEffect(() => {
        if (id) {
            getTicketByID();
        }
    }, [id]);

    return (
        <Row className='create-ticket-container-row'>
            {
                isLoading
                    ?
                    <SpinLoader />
                    :
                    <>
                        <Col sm={12} md={12} lg={6}>
                            <div className='create-ticket-header'>{t('tickets_module.ticket_details')}</div>
                            <Card className='create-ticket-card'>
                                <div className='ticket-accordion-container'>
                                    <Accordion defaultActiveKey="0" flush className='create-ticket-accordion'>
                                        <Accordion.Item eventKey={0}>
                                            <Accordion.Header>{t('tickets_module.general_details')}</Accordion.Header>
                                            <Accordion.Body className='ticket-general-details-body'>
                                                <form>
                                                    <Row>
                                                        <Col sm={12} md={12} lg={12}>
                                                            <label htmlFor="name">{t('name')}</label>
                                                            <input
                                                                {...register('name')}
                                                                type="text"
                                                                id="name"
                                                                placeholder={t('tickets_module.enter_name')}
                                                            />
                                                            {errors?.name && <span className="text-danger" style={{ fontSize: '12px' }}>{errors.name.message}</span>}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={12} md={12} lg={12}>
                                                            <label htmlFor="description">{t('description')}</label>
                                                            <div className="position-relative">
                                                                <textarea
                                                                    {...register('description')}
                                                                    rows={3}
                                                                    id="description"
                                                                    placeholder={t('tickets_module.enter_description')}
                                                                    maxLength={150}
                                                                />
                                                                <span className="char-count mt-1"
                                                                    style={{
                                                                        color: getValues('description').length === 150 ? 'red' : 'black',
                                                                    }}
                                                                >
                                                                    {getValues('description').length}/150
                                                                </span>
                                                            </div>
                                                            {errors?.description && <span className="text-danger" style={{ fontSize: '12px' }}>{errors.description.message}</span>}
                                                        </Col>
                                                    </Row>
                                                    <label>{t('tickets_module.benefits')}
                                                        {
                                                            errors?.benefits?.message &&
                                                            <span className="text-danger" style={{ fontSize: '12px' }}>&nbsp;({errors.benefits.message})</span>
                                                        }
                                                    </label>
                                                    <Row className='benefit-input-container'>
                                                        {fields.map((field, index) => (
                                                            <React.Fragment key={field.id}>
                                                                <Col sm={4} md={4} lg={4}>
                                                                    <div className="position-relative">
                                                                        <input
                                                                            {...register(`benefits.${index}`)}
                                                                            type="text"
                                                                            placeholder={`${t('benefit')} ${index + 1}`}
                                                                        />
                                                                        <FaRegTimesCircle size={15} className='delete-benefit' onClick={() => remove(index)} />
                                                                    </div>
                                                                    {errors?.benefits?.[index] && (
                                                                        <span className="text-danger" style={{ fontSize: '12px' }}>{errors.benefits[index].message}</span>
                                                                    )}
                                                                </Col>
                                                            </React.Fragment>
                                                        ))}
                                                        <Col sm={4} md={4} lg={4}>
                                                            <button
                                                                type="button"
                                                                onClick={async () => {
                                                                    const valid = await trigger('benefits'); // Trigger validation on all benefit inputs
                                                                    if (fields.length <= 0 || fields.length >= 12 || valid) {
                                                                        append(''); // Add a new input only if all current inputs are valid or if there are no current inputs
                                                                    }
                                                                }}
                                                                className='add-benefit-btn'
                                                            >
                                                                {t('add')}
                                                            </button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col sm={12} md={12} lg={12}>
                                                            <label htmlFor="price">{t('tickets_module.ticket_price')}</label>
                                                            <input
                                                                {...register('price')}
                                                                type='number'
                                                                pattern='[0-9]*'
                                                                onInput={(e) => {
                                                                    const value = e.target.value;
                                                                    const regex = /^\d+(\.\d{0,2})?$/;

                                                                    if (!regex.test(value)) {
                                                                        e.target.value = value.slice(0, -1);
                                                                    }
                                                                }}
                                                            />
                                                            {errors?.price && <span className="text-danger" style={{ fontSize: '12px' }}>{errors.price.message}</span>}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={6} md={6} lg={6}>
                                                            <label htmlFor="date">{t('tickets_module.event_date')}</label>
                                                            {/* {
                                                    <Controller
                                                        control={control}
                                                        name="eventDate"
                                                        render={({ field }) => (
                                                            <input
                                                                {...field}
                                                                value={moment(field.value).format('YYYY-MM-DD')}
                                                                onChange={(date) => field.onChange(date.target.valueAsNumber)}
                                                                type='date'
                                                            />
                                                        )}
                                                    />
                                                } */}
                                                            <input
                                                                {...register('eventDate')}
                                                                type='date'
                                                            />
                                                            {errors?.eventDate && <span className="text-danger" style={{ fontSize: '12px' }}>{errors.eventDate.message}</span>}
                                                        </Col>
                                                        <Col sm={6} md={6} lg={6} className='limits-col pr-0'>
                                                            <label htmlFor="limit">{t('tickets_module.set_limits')}</label>
                                                            <input {...register('limit')} type="number" id="limit" />
                                                            {errors?.limit && <span className="text-danger" style={{ fontSize: '12px' }}>{errors.limit.message}</span>}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={12} md={6} lg={6}>
                                                            <label htmlFor="endDate">{t('tickets_module.ticket_ends_desc')}</label>
                                                            <input
                                                                {...register('sellingEndDate')}
                                                                type='date'
                                                            />
                                                        </Col>
                                                    </Row>
                                                </form>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey={1}>
                                            <Accordion.Header>{t('tickets_module.ticket_apperance')}</Accordion.Header>
                                            <Accordion.Body className='ticket-theme-details-body'>
                                                <Row>
                                                    <Col sm={12} md={12} lg={12}>
                                                        <label>{t('theme')}</label>
                                                    </Col>
                                                </Row>
                                                <Row className='ticket-theme-row'>
                                                    <Col sm={4} md={4} lg={4}>
                                                        <input {...register('theme')} type="radio" value={TicketTheme.gold} className='form-check-input custom-radio' />
                                                        <div className="ticket-theme-div gold-theme"></div>
                                                    </Col>
                                                    <Col sm={4} md={4} lg={4}>
                                                        <input {...register('theme')} type="radio" value={TicketTheme.silver} className='form-check-input custom-radio' />
                                                        <div className="ticket-theme-div silver-theme"></div>
                                                    </Col>
                                                    <Col sm={4} md={4} lg={4}>
                                                        <input {...register('theme')} type="radio" value={TicketTheme.purple} className='form-check-input custom-radio' />
                                                        <div className="ticket-theme-div purple-theme"></div>
                                                    </Col>
                                                    {
                                                        errors?.theme && (
                                                            <span className="text-danger" style={{ fontSize: '12px' }}>{errors.theme.message}</span>
                                                        )
                                                    }
                                                </Row>
                                                <Row>
                                                    <Col sm={12} md={12} lg={12}>
                                                        <label>{t('tickets_module.background_image')}</label>
                                                    </Col>
                                                </Row>
                                                <Row className='ticket-bg-img-row mb-0'>
                                                    <Col sm={4} md={4} lg={4}>
                                                        <input {...register('background')} type="radio" value={TicketBackground.drink} className='form-check-input custom-radio' />
                                                        <img src={ticketNightClubOption} alt='Night Club' className="ticket-bg-img" />
                                                    </Col>
                                                    <Col sm={4} md={4} lg={4}>
                                                        <input {...register('background')} type="radio" value={TicketBackground.bulb} className='form-check-input custom-radio' />
                                                        <img src={ticketDiscoBallOption} alt='Disco Ball' className="ticket-bg-img" />
                                                    </Col>
                                                    <Col sm={4} md={4} lg={4}>
                                                        <input {...register('background')} type="radio" value={TicketBackground.dance} className='form-check-input custom-radio' />
                                                        <img src={ticketDanceClubOption} alt='Dance Club' className="ticket-bg-img" />
                                                    </Col>
                                                    {
                                                        errors?.background && (
                                                            <span className="text-danger" style={{ fontSize: '12px' }}>{errors.background.message}</span>
                                                        )
                                                    }
                                                </Row>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                                <div className="create-ticket-buttons">
                                    <PrimaryButton title={t('tickets_module.create_ticket')} mode='full' height='40' onClick={handleSubmit(handleSave)} />
                                </div>
                            </Card>
                        </Col>
                        <Col sm={12} md={12} lg={6} className='mt-md-0 mt-4'>
                            <div className='create-ticket-header'>{t('preview')}</div>
                            <Card className='ticket-preview-card'>
                                <div className="ticket-preview-wrapper">
                                    <TicketPreview ticket={getValues()} />
                                </div>
                                <div className="mt-3 ticket-detail-content">
                                    <div className="ticket-detail-header fw-semibold">
                                        <p>{formData.name || 'Ticket'}</p>
                                        <p>€ {formData.price || 0}</p>
                                    </div>
                                    <div className="ticket-detail-body">
                                        <div className="ticket-detail-description">
                                            {formData.description || 'Ticket Description'}
                                        </div>
                                        <ul style={{ width: "40%" }}>
                                            {
                                                formData.benefits.length ?
                                                    formData.benefits.map((benefit, index) => {
                                                        return <li key={index}>{benefit}</li>
                                                    })
                                                    :
                                                    <li>{t('tickets_module.add_benefits')}</li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Toast ref={toast} position='top-right' />
                    </>
            }
        </Row >
    )
}

export default CreateTicket