import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { DollarSack, Customers, Expenses } from "../../../assets/images";
import { GoArrowUpRight } from "react-icons/go";
import { FaBell } from "react-icons/fa";
import { Chart } from "primereact/chart";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./homepage.css";
import { useTranslation } from "react-i18next";

const Homepage = () => {
    const { t } = useTranslation();

    const [barChartData, setBarChartData] = useState({});
    const [barChartOptions, setBarChartOptions] = useState({});

    const [lineChartData, setLineChartData] = useState({});
    const [lineChartOptions, setLineChartOptions] = useState({});

    useEffect(() => {
        const barChartData = {
            labels: [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
            ],
            datasets: [
                {
                    label: "Splendid Nights",
                    backgroundColor: "#CBBB65",
                    borderRadius: 5,
                    data: [65, 59, 80, 81, 56, 55, 40],
                },
                {
                    label: "Highlife Nights",
                    backgroundColor: "#B7B7B7",
                    borderRadius: 5,
                    data: [28, 48, 40, 19, 86, 27, 90],
                },
                {
                    label: "Fun Fantastic",
                    backgroundColor: "#7A38FE",
                    borderRadius: 5,
                    data: [28, 48, 40, 19, 86, 27, 90],
                },
            ],
        };
        const barChartOptions = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    align: "center",
                    position: "bottom",
                    labels: {
                        font: {
                            size: 12,
                            family: '"DM Sans", sans-serif',
                        },
                        usePointStyle: true,
                        pointStyle: "circle",
                        boxWidth: 10,
                        boxHeight: 10,
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        font: {
                            size: 12,
                            family: '"DM Sans", sans-serif',
                        },
                    },
                    grid: {
                        display: false,
                    },
                },
                y: {
                    ticks: {
                        display: false,
                    },
                    grid: {
                        display: false,
                        drawBorder: false,
                    },
                },
            },
        };

        const lineChartData = {
            labels: [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
            ],
            datasets: [
                {
                    label: "Dataset 1",
                    fill: false,
                    tension: 0.4,
                    data: [65, 59, 80, 81, 56, 55, 10],
                    borderWidth: 1,
                    borderColor: "#7A38FE",
                    backgroundColor: "#7A38FE",
                    pointRadius: 0,
                },
            ],
        };

        const lineChartOptions = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    display: false,
                },
            },
            scales: {
                x: {
                    ticks: {
                        font: {
                            size: 12,
                            family: '"DM Sans", sans-serif',
                        },
                    },
                    grid: {
                        display: false,
                        drawBorder: false,
                    },
                },
                y: {
                    type: "linear",
                    display: true,
                    position: "left",
                    ticks: {
                        font: {
                            size: 10,
                            family: '"DM Sans", sans-serif',
                        },
                    },
                    grid: {
                        drawBorder: false,
                    },
                    beginAtZero: true,
                },
            },
        };

        setBarChartData(barChartData);
        setBarChartOptions(barChartOptions);
        setLineChartData(lineChartData);
        setLineChartOptions(lineChartOptions);
    }, []);

    return (
        <div className="business-dashboard-container">
            <Row className="h-100">
                <Col xs={12} md={8}>
                    <Card className="business-dashboard-card">
                        <Row>
                            <Col xs={12} md={12}>
                                <h4>{t("business_home.dashboard")}</h4>
                            </Col>
                        </Row>

                        {/* Widgets [Start] */}
                        <Row className="mt-3">
                            <Col xs={12} md={4}>
                                <Card className="business-home-widget-card business-home-cards">
                                    <div className="widget-img">
                                        <img
                                            src={DollarSack}
                                            alt="DollarSack"
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "contain",
                                            }}
                                        />
                                    </div>
                                    <div className="">
                                        <p className="widget-amount">€ 2,500</p>
                                        <p className="widget-title">{t('business_home.income')}</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col xs={12} md={4}>
                                <Card className="business-home-widget-card business-home-cards">
                                    <div className="widget-img">
                                        <img
                                            src={Customers}
                                            alt="Customers"
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "contain",
                                            }}
                                        />
                                    </div>
                                    <div className="">
                                        <p className="widget-amount">€ 2,500</p>
                                        <p className="widget-title">
                                            {t('business_home.total_spend')}
                                        </p>
                                    </div>
                                </Card>
                            </Col>
                            <Col xs={12} md={4}>
                                <Card className="business-home-widget-card business-home-cards">
                                    <div className="widget-img">
                                        <img
                                            src={Expenses}
                                            alt="Expenses"
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "contain",
                                            }}
                                        />
                                    </div>
                                    <div className="">
                                        <p className="widget-amount">2,500</p>
                                        <p className="widget-title">
                                            {t('business_home.global_customers')}
                                        </p>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                        {/* Widgets [End] */}

                        {/* Charts [Start] */}
                        <Row className="mt-3">
                            <Col xs={12} md={6}>
                                <Card className="business-home-cards">
                                    <div className="header-row">
                                        <h6>
                                            {t('business_home.customers')} <span>({t('business_home.this_week')})</span>
                                        </h6>
                                        <div className="d-flex align-items-center justify-content-center gap-3">
                                            <p className="header-value">326</p>
                                            <span className="header-badge">
                                                <GoArrowUpRight
                                                    size={15}
                                                    color="#458E33"
                                                />{" "}
                                                21.56%
                                            </span>
                                        </div>
                                    </div>

                                    <div className="">
                                        <Chart
                                            type="line"
                                            data={lineChartData}
                                            options={lineChartOptions}
                                            height="220px"
                                        />
                                    </div>
                                </Card>
                            </Col>
                            <Col xs={12} md={6}>
                                <Card className="business-home-cards">
                                    <div className="header-row">
                                        <h6>{t('business_home.tickets_sold')}</h6>
                                        <div className="d-flex align-items-center justify-content-center gap-3">
                                            <p className="header-value">1622</p>
                                            <span className="header-badge">
                                                <GoArrowUpRight
                                                    size={15}
                                                    color="#458E33"
                                                />{" "}
                                                20%
                                            </span>
                                        </div>
                                    </div>

                                    <div className="">
                                        <Chart
                                            type="bar"
                                            data={barChartData}
                                            options={barChartOptions}
                                            height="220px"
                                        />
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                        {/* Charts [End] */}

                        <Row className="mt-3">
                            <Col xs={12} md={12}>
                                <Card className="business-home-cards">
                                    {true
                                        ?
                                        <div>
                                            <div className="header-row">
                                                <h6>{t('business_home.top_affiliates')}</h6>
                                                <button className="view-all-btn">
                                                    {t('view_all')}
                                                </button>
                                            </div>

                                            <div className="business-home-table">
                                                <DataTable
                                                    scrollable
                                                    scrollHeight="160px"
                                                    value={[
                                                        {
                                                            rank: 1,
                                                            name: "johndoe@me.com",
                                                            instagram:
                                                                "@johndoe",
                                                            date: "10 Sept, 2024",
                                                            commission: "2%",
                                                            leads: "10",
                                                        },
                                                        {
                                                            rank: 1,
                                                            name: "johndoe@me.com",
                                                            instagram:
                                                                "@johndoe",
                                                            date: "10 Sept, 2024",
                                                            commission: "2%",
                                                            leads: "10",
                                                        },
                                                        {
                                                            rank: 1,
                                                            name: "johndoe@me.com",
                                                            instagram:
                                                                "@johndoe",
                                                            date: "10 Sept, 2024",
                                                            commission: "2%",
                                                            leads: "10",
                                                        },
                                                        {
                                                            rank: 1,
                                                            name: "johndoe@me.com",
                                                            instagram:
                                                                "@johndoe",
                                                            date: "10 Sept, 2024",
                                                            commission: "2%",
                                                            leads: "10",
                                                        },
                                                    ]}
                                                    className={`responsive-table affiliate-marketing-data-table ${
                                                        [].length === 0
                                                            ? "empty-data-table"
                                                            : ""
                                                    }`}
                                                    resizableColumns={false}
                                                    emptyMessage={t(
                                                        "no_data_found"
                                                    )}
                                                    readOnly
                                                >
                                                    <Column
                                                        field="rank"
                                                        header={t("rank")}
                                                        headerClassName="rank-col"
                                                        align="left"
                                                    ></Column>
                                                    <Column
                                                        field="name"
                                                        header={t("name")}
                                                        headerClassName="name-col"
                                                    ></Column>
                                                    <Column
                                                        field="instagram"
                                                        header="Instagram"
                                                        headerClassName="email-col"
                                                        align="center"
                                                    ></Column>
                                                    <Column
                                                        field="date"
                                                        header="Date"
                                                        headerClassName="commission-rate-col"
                                                        align="center"
                                                    ></Column>
                                                    <Column
                                                        field="commission"
                                                        header="Commission"
                                                        align="center"
                                                        headerClassName="generated-leads-col"
                                                    ></Column>
                                                    <Column
                                                        field="leads"
                                                        header="Leads"
                                                        align="center"
                                                        headerClassName="generated-leads-col"
                                                        className="text-success"
                                                    ></Column>
                                                </DataTable>
                                            </div>
                                        </div>
                                        :
                                        <div className="disabled-affiliate-marketing">
                                            <div className="">
                                                <h5>Affiliate Marketing is Deactivated</h5>
                                                <p>Kindly activate to track Top Affiliates</p>
                                            </div>
                                            <button>{t('business_home.activate')}</button>
                                        </div>
                                    }
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col xs={12} md={4}>
                    <Row>
                        <Col xs={12} md={12}>
                            <Card className="business-home-cards business-dashbaord-club-card">
                                <div className="club-image">
                                    <img src="" alt="Lion's Club" />
                                </div>
                                <div className="club-details">
                                    <div className="">
                                        <h5>The Lion's Club</h5>
                                        <p>Manage</p>
                                    </div>
                                    <button>
                                        <GoArrowUpRight
                                            size={25}
                                            color="#FFFFFF"
                                            cursor="pointer"
                                        />
                                    </button>
                                </div>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={12}>
                            <Card className="business-home-cards business-dashbaord-ads-card">
                                {true ? (
                                    <>
                                        <div className="home-ads-header">
                                            <h5>Active Ad Plan</h5>
                                            <button>{t('view_more')}</button>
                                        </div>

                                        <div className="home-ads-content">
                                            <span>
                                                <FaBell
                                                    size={20}
                                                    color="#FFFFFFF"
                                                />
                                            </span>
                                            <p>Notify Customers</p>
                                        </div>
                                    </>
                                ) : (
                                    <div className="d-flex flex-column justify-content-center align-items-center gap-3">
                                        <h5>No Activated Ads</h5>
                                        <div className="home-ads-header">
                                            <button>Upgrade</button>
                                        </div>
                                    </div>
                                )}
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={12}>
                            <Card className="business-home-cards business-dashbaord-nationality-card">
                                <h5>Nationality Distribution</h5>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default Homepage;