import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdClose } from "react-icons/io";
import { MdOutlineLogout } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { blacklogo } from '../../../assets/images';
import { ReactComponent as FrizdaAdsActiveIcon } from '../../../assets/svg/ads-active.svg';
import { ReactComponent as AffiliateActiveIcon } from '../../../assets/svg/affiliate-marketing-active.svg';
import { ReactComponent as AffiliateIcon } from '../../../assets/svg/affiliateSvgSidebar.svg';
import { ReactComponent as ClientDataActiveIcon } from '../../../assets/svg/client-data-active.svg';
import { ReactComponent as ClientDataIcon } from '../../../assets/svg/clientDataSvgSidebar.svg';
import { ReactComponent as FrizdaAdsIcon } from '../../../assets/svg/frizdaAdsSvgSidebar.svg';
import { ReactComponent as HelpIcon } from '../../../assets/svg/helpSvgSidebar.svg';
import MoneticsActiveIcon from '../../../assets/svg/moneticsActive.svg';
import MoneticsIcon from '../../../assets/svg/moneticsSvgSidebar.svg';
import { ReactComponent as NightspotDetailsIcon } from '../../../assets/svg/nightSpotDetailSvgSidebar.svg';
import { ReactComponent as NightSpotActiveIcon } from '../../../assets/svg/nightSpotSvgActive.svg';
import { ReactComponent as TicketsActiveIcon } from '../../../assets/svg/tickets-active.svg';
import { ReactComponent as TicketsIcon } from '../../../assets/svg/ticketsSvgSidebar.svg';
import { authActions } from '../../../redux/authslice';
import { ApiPost } from '../../../utils/ApiData';
import { FaGear } from 'react-icons/fa6';
import { TiHome } from "react-icons/ti";

const SideBar = ({ setIsSidebarCollapsed, isSidebarCollapsed }) => {
    const { t } = useTranslation();

    const menuItems = [
        { name: t('home'), href: 'home', icon: (isSelected) => <TiHome size={20} color={isSelected ? "#FFFFFF" : "#555555"} /> },
        { name: t('monetics'), href: 'monetics', icon: (isSelected) => <img src={isSelected ? MoneticsActiveIcon : MoneticsIcon} alt="monetics" style={{ height: "18px", width: "18px", objectFit: "contain" }} /> },
        { name: t('frizda_ads'), href: 'frizda-ads', icon: (isSelected) => isSelected ? <FrizdaAdsActiveIcon /> : <FrizdaAdsIcon /> },
        { name: t('client_data'), href: 'client-data', icon: (isSelected) => isSelected ? <ClientDataActiveIcon /> : <ClientDataIcon /> },
        { name: t('nightspot_details'), href: 'nightspot-details', icon: (isSelected) => isSelected ? <NightSpotActiveIcon /> : <NightspotDetailsIcon /> },
        { name: t('affiliate_marketing'), href: 'affiliate-marketing', icon: (isSelected) => isSelected ? <AffiliateActiveIcon /> : <AffiliateIcon /> },
        { name: t('tickets'), href: 'tickets', icon: (isSelected) => isSelected ? <TicketsActiveIcon /> : <TicketsIcon /> },
        { name: t('settings'), href: 'settings', icon: (isSelected) => <FaGear size={18} color={isSelected ? "#FFFFFF" : "#555555"} /> },
    ];
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [selectedItem, setSelectedItem] = useState('Nightspot Details');
    const dispatch = useDispatch();

    const navigateTo = (path) => {
        navigate(`/business/dashboard/${path.href}`);
    }

    const logout = async () => {
        try {
            await ApiPost('user/signout');
            dispatch(authActions.logout());
            navigate('/business/login');
        }
        catch (error) {
            console.error(error);
        }
    }

    // to set active menu item
    useEffect(() => {
        setSelectedItem(menuItems.find(item => pathname.includes(item.href))?.name);
        // setSelectedItem(menuItems.find(item => item.href === pathname.split('/').pop())?.name);
    }, [pathname]);

    return (
        <div className={`dashboard-sidebar border-end ${isSidebarCollapsed ? '' : 'active'}`}>
            <div className='d-none d-lg-flex flex-row align-self-center mt-2' style={{ height: "80px" }}>
                <div style={{ height: "17px", width: "92px" }}>
                    <img className='h-100 w-100' src={blacklogo} alt="Frizda"></img>
                </div>
            </div>
            <ul className="pt-3 pt-lg-0 nav nav-pills flex-column mb-auto">
                {menuItems.map((item, index) => (
                    <li key={index} className="nav-item mb-4" style={{ marginLeft: "10px", cursor: "pointer" }}>
                        <div className={`nav-link d-flex align-items-center`}
                            style={{ backgroundColor: selectedItem === item.name ? '#7a38fe' : '#fff', }} onClick={() => { setIsSidebarCollapsed(true); navigateTo(item); }}>
                            {item.icon(selectedItem === item.name)}
                            <span className="ms-2" style={{ color: selectedItem === item.name ? '#fff' : '#555555', fontSize: "16px", fontFamily: "DM Sans", fontWeight: "bold" }}>{item.name}</span>
                        </div>
                    </li>
                ))}
                <li className="nav-item mb-4" style={{ marginLeft: "10px", cursor: "pointer" }}>
                    <div className='nav-link d-flex align-items-center' onClick={() => { }}>
                        <MdOutlineLogout size={20} color='#555555' />
                        <span className="ms-2" style={{ color: '#555555', fontSize: "16px", fontFamily: "DM Sans", fontWeight: "bold" }} onClick={logout}>Logout</span>
                    </div>
                </li>
            </ul>
            <div className="">
            </div>
            <div className='d-flex flex-row align-items-center justify-content-between px-4' style={{ marginBottom: '10%' }}>
                <HelpIcon />
                <span style={{ fontSize: "12px", fontWeight: "bold", color: "#555555", fontFamily: "Dm Sans" }}>{t('help_and_get_started')}</span>
            </div>
            <div className="d-block d-lg-none" style={{ position: "absolute", top: "20px", right: "20px" }}>
                <IoMdClose size={28} color='#000000' onClick={() => setIsSidebarCollapsed(prev => !prev)} />
            </div>
        </div>
    );
};

export default SideBar;
