// BusinessRoutes.js
import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

// ↓ Data providers
import { SelectedPlanProvider } from "../businessStates&Auth/SelectedPlanState";
import { LoggedInProvider } from "../businessStates&Auth/businessAuthState";
import { NewNightspotProvider } from "../businessStates&Auth/newNighstpotState";
import { ApiGet } from "../../utils/ApiData";

export default function BusinessRoutes() {
    const navigate = useNavigate();

    const validateCookieUser = async () => {
        try {
            const user = await ApiGet("user/");
            if (user) {
                navigate("/business/dashboard");
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        validateCookieUser();
    }, []);
    
    return (
        <NewNightspotProvider>
            <LoggedInProvider>
                <SelectedPlanProvider>
                    {/* <Routes>
                        <Route path='/account' element={<AccountDashboard />} />
                        <Route path='/frizda-coins' element={<FrizdaCoins />} />
                        <Route path='/about' element={<About />} />
                        <Route path='/' element={<LandingPage />} />
                        <Route path='/add' element={<AddClub />} />
                        <Route path='/signup' element={<SignUpForBusiness />} />
                        <Route path='/add/nightclub-details' element={<NightclubDetails />} />
                        <Route path='/add/plans' element={<BusinessPlans />} />
                        <Route path='/add/card-readers' element={<CardReaders />} />

                        <Route path='/login' element={<LogIn />} />
                        <Route path='/forgot-password' element={<ForgotPassword />} />
                        <Route path='/reset-password' element={<ResetPassword />} />
                        <Route path='/check-email' element={<CheckEmail />} />
                        <Route path='/verify-email' element={<VerifyEmail />} />
                        <Route path='/plans' element={<PlanPage />} />
                        <Route path='/service-home' element={<ServiceHomePage />} />

                        
                                <Route path="/dashboard" element={<FrizdaDashboard />}>
                                    <Route index element={<Navigate to="home" />} />
                                    <Route path="home" element={<div>Home</div>} />
                                    <Route path="frizda-ads" element={<FrizdaAds />} />
                                    <Route path="nightspot-details" element={<NightspotDetails />} />
                                    <Route path="monetics" element={<MoneticsHome />} />
                                    <Route path="client-data" element={<ClientData />} />
                                    <Route path="affiliate-marketing" element={<AffiliateMarketing />} />
                                    <Route path="tickets" element={<Tickets />} >
                                        <Route index element={<TicketsHome />} />
                                        <Route path=":id" element={<TicketDetails />} />
                                        <Route path="create" element={<CreateTicket />} />
                                        <Route path="edit/:id" element={<CreateTicket />} />
                                    </Route>
                                </Route>
                    </Routes> */}

                    <Outlet />
                </SelectedPlanProvider>
            </LoggedInProvider>
        </NewNightspotProvider>
    );
}
