import React, { useEffect, useState } from "react";
import {
    redsys,
    square,
    stripe,
    sumup,
    verifone,
} from "../../../../assets/images";
import { FaArrowRightLong } from "react-icons/fa6";
import "../settings.css";

const CardReadersTab = () => {
    const CARD_READERS = [
        {
            id: 1,
            name: "Stripe",
            image: stripe,
        },
        {
            id: 2,
            name: "SumUp",
            image: sumup,
        },
        {
            id: 3,
            name: "Redsys",
            image: redsys,
        },
        {
            id: 4,
            name: "Square",
            image: square,
        },
        {
            id: 5,
            name: "Verifone",
            image: verifone,
        },
    ];

    const [cardReaders, setCardReaders] = useState([]);
    const [selectedCardReader, setSelectedCardReader] = useState([]);

    const handleSelectCardReader = (reader) => {
        const isSelected = selectedCardReader.some(
            (selected) => selected.id === reader.id
        );
        if (isSelected) {
            setSelectedCardReader(
                selectedCardReader.filter(
                    (selected) => selected.id !== reader.id
                )
            );
        } else {
            setSelectedCardReader([...selectedCardReader, reader]);
        }
    };

    const addCardReaders = () => {};

    useEffect(() => {
        setCardReaders(CARD_READERS);
    }, []);

    return (
        <div className="card-readers-container">
            <div className="active-card-readers-container">
                <h3>Card Readers in Use</h3>
                <div className="card-reader-brand-container">
                    <div className="card-reader-brand active-card-reader-brand">
                        <img src={square} alt="Square" />
                    </div>
                </div>
            </div>

            <div className="add-card-readers-container">
                <h3>Select brand of Card Readers</h3>
                <div className="card-reader-brand-container">
                    {cardReaders.length > 0 && cardReaders.map((cardReader) => (
                        <div
                            key={cardReader.id}
                            className={`card-reader-brand ${
                                selectedCardReader.some(
                                    (selected) => selected.id === cardReader.id
                                )
                                    ? "active-card-reader-brand"
                                    : ""
                            }`}
                            onClick={() => handleSelectCardReader(cardReader)}
                        >
                            <img src={cardReader.image} alt={cardReader.name} />
                        </div>
                    ))}
                    <button className="add-card-readers-btn" onClick={addCardReaders}>Add Card/s <FaArrowRightLong size={20} color="white" style={{ marginLeft: "10px" }} /></button>
                </div>
            </div>
        </div>
    );
};

export default CardReadersTab;
