import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiMenuAlt3 } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import { IoLanguage } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { userLogo, whiteLogo } from "../../assets/images";
import { supportedLngs } from '../../utils/i18n';
import './styles/LandingNavbar.css';

const LandingNavbar = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(true);

    const { t, i18n } = useTranslation();
    const { isLoggedIn, user } = useSelector((state) => state.auth);

    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
    };

    const handleLanguageChange = (language) => {
        i18n.changeLanguage(language);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };

    }, []);

    return (
        <>
            <nav className={`nav-container ${isScrolled ? "scrolled" : ""}`}>
                <div className="nav-logo">
                    <img src={whiteLogo} alt="Frizda" className='w-100 h-100 object-fit-contain' />
                </div>
                <div className="nav-links">
                    <Link to="/business" className='nav-link'>{t('landing_page.navbar.home')}</Link>
                    <Link to="/business" className='nav-link'>{t('landing_page.navbar.features')}</Link>
                    <Link to="/business" className='nav-link'>{t('landing_page.navbar.pricing')}</Link>
                    <Link to="/business" className='nav-link'>{t('landing_page.navbar.about')}</Link>

                    <div className="d-flex align-items-center">
                        <IoLanguage size={20} />
                        <select
                            className="nav-link language-select"
                            value={i18n.language}
                            onChange={(e) => handleLanguageChange(e.target.value)}
                        >
                            {Object.entries(supportedLngs).map(([code, name]) => (
                                <option value={code} key={code}>
                                    {name}
                                </option>
                            ))}
                        </select>
                    </div>

                    {!isLoggedIn
                        ?
                        <Link to="/business/login" className='sign-in-btn'>{t('landing_page.navbar.login')}</Link>
                        :
                        <div className='nav-profile-container'>
                            <p>{user.firstname} {user.lastname}</p>
                            <div className="nav-profile-pic">
                                <img src={userLogo} alt="Frizda" className='w-100 h-100 object-fit-contain' />
                            </div>
                        </div>
                    }
                </div>
                <div className="d-md-none d-flex align-items-center gap-2">
                    {!isLoggedIn
                        ?
                        <Link to="/business/login" className='sign-in-btn'>{t('landing_page.navbar.login')}</Link>
                        :
                        <div className='nav-profile-container'>
                            <p>{user.firstname} {user.lastname}</p>
                            <div className="nav-profile-pic">
                                <img src={userLogo} alt="Frizda" className='w-100 h-100 object-fit-contain' />
                            </div>
                        </div>
                    }
                    <HiMenuAlt3 size={28} color='#FFFFFF' onClick={toggleSidebar} />
                </div>
            </nav>
            <div className={`d-md-none sidebar ${isCollapsed ? "collapsed" : ""}`}>
                <div className="sidebar-links">
                    <Link to="/business" className='nav-link'>{t('landing_page.navbar.home')}</Link>
                    <Link to="/business" className='nav-link'>{t('landing_page.navbar.features')}</Link>
                    <Link to="/business" className='nav-link'>{t('landing_page.navbar.pricing')}</Link>
                    <Link to="/business" className='nav-link'>{t('landing_page.navbar.about')}</Link>
                </div>
                <div className="d-block d-lg-none" style={{ position: "absolute", top: "20px", right: "20px" }}>
                    <IoMdClose size={28} color='#000000' onClick={toggleSidebar} />
                </div>
            </div>
        </>
    )
}

export default LandingNavbar