import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaChevronDown } from 'react-icons/fa';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import SpinLoader from '../../../components/home-page/loaders/SpinLoader';
import { ApiGet } from '../../../utils/ApiData';
import { DATA_PER_PAGE } from '../../../utils/constants';
import TicketPreview from './TicketPreview';

const TicketsHome = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);
    const [hasNext, setHasNext] = useState(false);
    const [ticketData, setTicketData] = useState([]);
    const [recentPurchaseData, setRecentPurchaseData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const loadMoreData = () => {
        if (!hasNext) {
            return;
        }
        setCurrentPage(currentPage + 1);
    };

    const getAllTickets = async () => {
        setIsLoading(true);
        try {
            const response = await ApiGet(`ticket/get-all?page=${currentPage}&limit=${DATA_PER_PAGE}`);
            if (response) {
                setTicketData([...ticketData, ...response?.data?.tickets]);
                if (response?.metadata?.hasNext) {
                    setHasNext(true);
                }
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error(error);
        }
    }

    useEffect(() => {
        getAllTickets();
    }, [currentPage]);

    return (
        <div className='h-100 w-100'>
            {
                isLoading
                    ?
                    <SpinLoader />
                    :
                    <>
                        <div className="tickets-dashboard-header">
                            <p>{t('tickets_module.your_tickets')}</p>
                            <button type='button' onClick={() => { navigate('/business/dashboard/tickets/create'); }}>{t('tickets_module.create_ticket')}</button>
                        </div>

                        <Row className='mt-3 mb-4'>
                            <Col sm={12}>
                                <div className="tickets-swiper-wrapper">
                                    <Swiper
                                        slidesPerView={3}
                                        spaceBetween={20}
                                        navigation={{
                                            nextEl: '.swiper-right-arrow',
                                            prevEl: '.swiper-left-arrow',
                                        }}
                                        modules={[Navigation]}
                                        className="tickets-swiper"
                                        breakpoints={{
                                            320: {
                                                slidesPerView: 1,
                                            },
                                            576: {
                                                slidesPerView: 1,
                                            },
                                            768: {
                                                slidesPerView: 2,
                                                spaceBetween: 40,
                                            },
                                            1024: {
                                                slidesPerView: 3,
                                                spaceBetween: 50,
                                            },
                                        }}
                                    >
                                        {
                                            ticketData.map((data, index) =>
                                                <SwiperSlide key={index}>
                                                    <TicketPreview ticket={data} isEditMode />
                                                </SwiperSlide>
                                            )
                                        }
                                    </Swiper>
                                    <div className="ticket-swiper-pagination">
                                        <FaChevronLeft className='swiper-left-arrow' />
                                        <FaChevronRight className='swiper-right-arrow' />
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Card className='data-table-card'>
                            <p className='data-table-header'>{t('tickets_module.recent_purchases')}</p>
                            <div className="data-table-wrapper">
                                <DataTable
                                    value={recentPurchaseData}
                                    className={`responsive-table ticket-data-table ${recentPurchaseData.length === 0 ? 'empty-data-table' : ''}`}
                                    resizableColumns={false}
                                    emptyMessage="No Data Found"
                                    readOnly
                                >
                                    <Column field="txnId" header="Txn ID" headerClassName='txn-id-col' bodyClassName='txn-id-col' body={(rowData) => `#${rowData.txnId}`}></Column>
                                    <Column field="id" header={t("tickets_module.ticket_id")} headerClassName='ticket-id-col' bodyClassName='ticket-id-col'></Column>
                                    <Column field="name" header={t("tickets_module.ticket_name")}></Column>
                                    <Column field="customerName" header={t("tickets_module.customer_name")}></Column>
                                    <Column field="paymentDate" header={t("tickets_module.payment_date")}></Column>
                                    <Column field="paymentTime" header={t("tickets_module.payment_time")} headerClassName='payment-time-col' bodyClassName='payment-time-col'></Column>
                                    <Column field="paymentAmount" header={t("tickets_module.payment_amount")} body={(rowData) => `€ ${rowData.paymentAmount}`}></Column>
                                </DataTable>
                                {
                                    hasNext &&
                                    <div className="data-table-footer">
                                        <span style={{ cursor: 'pointer' }} onClick={loadMoreData}>
                                            {t('show_more')} <FaChevronDown />
                                        </span>
                                    </div>
                                }
                            </div>
                        </Card>
                    </>
            }
        </div>
    )
}

export default TicketsHome