import React from 'react'
import { FaBell } from 'react-icons/fa';
import { blacklogo, userLogo } from '../../../assets/images';
import { HiMenuAlt3 } from "react-icons/hi";
import { useTranslation } from 'react-i18next';
import { IoLanguage } from 'react-icons/io5';
import { supportedLngs } from '../../../utils/i18n';

const Navbar = ({ setIsSidebarCollapsed }) => {

    const { i18n } = useTranslation();

    const handleLanguageChange = (language) => {
        i18n.changeLanguage(language);
    };


    return (
        <nav className="navbar-container">
            <div className='w-100 d-flex d-lg-none align-items-center justify-content-between'>
                <div className='navbar-sm-logo'>
                    <img className='h-100 w-100' style={{ objectFit: 'contain' }} src={blacklogo}></img>
                </div>
                <div className='p-1' onClick={() => setIsSidebarCollapsed(prev => !prev)}>
                    <HiMenuAlt3 size={28} color='#000000' />
                </div>
            </div>
            <div className="d-none container d-lg-flex justify-content-end gap-2">
                <div className="nav-link d-flex align-items-center text-dark gap-3">
                    <div className="d-flex align-items-center text-black">
                        <IoLanguage size={20} />
                        <select
                            className="nav-link language-select"
                            value={i18n.language}
                            onChange={(e) => handleLanguageChange(e.target.value)}
                        >
                            {Object.entries(supportedLngs).map(([code, name]) => (
                                <option value={code} key={code}>
                                    {name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <FaBell size={20} />
                </div>
                <div className="nav-link d-flex align-items-center text-dark ms-4">
                    <div style={{ height: 40, width: 40 }}>
                        <img className='h-100 w-100' src={userLogo}></img>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navbar