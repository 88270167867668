import moment from 'moment';
import { InputSwitch } from 'primereact/inputswitch';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPen } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { ticketDanceClub, ticketDiscoBall, ticketNightClub } from '../../../assets/images';
import { TicketBackground } from '../../../utils/constants';

const TicketPreview = ({ ticket, isEditMode = false }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const [isEnabled, setIsEnabled] = useState(false);
    const [bgImage, setBgImage] = useState(null);

    useEffect(() => {
        if (ticket?.background === TicketBackground.drink) {
            setBgImage(ticketNightClub);
        } else if (ticket?.background === TicketBackground.bulb) {
            setBgImage(ticketDiscoBall);
        } else if (ticket?.background === TicketBackground.dance) {
            setBgImage(ticketDanceClub);
        }

        setIsEnabled(ticket?.isActive);
    }, [ticket]);

    return (
        <div className='ticket-preview-container'>
            <div className={`ticket-main ${ticket?.theme ? ticket?.theme?.toLowerCase() : ''}-theme`}>
                <p className='ticket-name'>{ticket?.name || t('ticket')}</p>
                <p className='ticket-date-vanue'>{ticket?.eventDate && moment(ticket?.eventDate).format('DD/MM/YYYY') || t('date')}</p>
                {
                    bgImage ? <img src={bgImage} alt={ticket?.name} className='ticket-bg-image' /> : null
                }
            </div>
            <div className="ticket-separator"></div>
            <div className={`ticket-extra ${ticket?.theme ? ticket?.theme?.toLowerCase() : ''}-extra`}>
                {
                    isEditMode &&
                    (
                        <>
                            <div className="d-flex align-items-center justify-content-center gap-1 cursor-pointer" onClick={() => navigate(`/business/dashboard/tickets/edit/${ticket?.id}`)}>
                                <p>{t('EDIT')}</p>
                                <FaPen size={15} color='#545454' />
                            </div>
                            {
                                ticket?.sellingEndDate &&
                                <div className="text-center">
                                    <p style={{ fontSize: '12px' }}>{t('end_date')}</p>
                                    <p>{ticket?.sellingEndDate && moment(ticket?.sellingEndDate).format('DD/MM/YYYY') || ''}</p>
                                </div>
                            }
                            <div className="ticket-extra-switch-wrapper">
                                <InputSwitch className='ticket-switch' checked={isEnabled} onChange={(e) => setIsEnabled(e.value)} />
                                <p className={`${isEnabled ? '' : 'ticket-inactive'}`}>{t('ENABLED')}</p>
                            </div>
                        </>
                    )
                }
            </div>
        </div>
    )
}

export default TicketPreview