import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { motion } from 'framer-motion'; // For slide animation
import 'mapbox-gl/dist/mapbox-gl.css'; // Mapbox styles
import React, { useEffect, useRef, useState } from 'react';
import PrimaryButton from '../components/PrimaryButton';
import SecondaryButton from '../components/SecondaryButton';
import { useTranslation } from 'react-i18next';

// Set the access token
const accessToken = 'pk.eyJ1IjoiZnJpemRhIiwiYSI6ImNsemIydmdnejAyYjIyaXM1NWdoOW5tdm4ifQ.X9D178XIhhdHxEYv-5dwgw';
mapboxgl.accessToken = accessToken;

const NightspotAddress = ({
    nextSlide,
    prevSlide,
    delta,
    register,
    errors,
    getValues,
    setValue,
    isEditMode,
    editModeSaveHandler,
    isMediumOrSmallScreen
}) => {
    const { t } = useTranslation();
    const [coordinates, setCoordinates] = useState(null);
    const [initialSearchAddress, setInitialSearchAddress] = useState('');
    const [initialSearchCoordinates, setInitialSearchCoordinates] = useState(null);
    const mapContainer = useRef(null);
    const map = useRef(null);
    const markerRef = useRef(null);
    const intersectionObserverRef = useRef(null); // to observe when map component is visible in viewport

    const handleMapClick = (event) => {
        const { lngLat } = event;
        setCoordinates({ latitude: lngLat.lat, longitude: lngLat.lng });
        setValue('address.coordinateAddress', { latitude: lngLat.lat, longitude: lngLat.lng });
        console.log(`Coordinates: ${lngLat.lat}, ${lngLat.lng}`);
    };

    const handleCoordinateChange = (e) => {
        const { name, value } = e.target;
        setCoordinates((prevCoordinates) => ({
            ...prevCoordinates,
            [name]: parseFloat(value),
        }));
        setValue('address.coordinateAddress', {
            latitude: coordinates.latitude,
            longitude: coordinates.longitude,
        });
    };

    const calculateDistance = (coord1, coord2) => {
        const R = 6371e3; // Radius
        const φ1 = coord1.latitude * Math.PI / 180;
        const φ2 = coord2.latitude * Math.PI / 180;
        const Δφ = (coord2.latitude - coord1.latitude) * Math.PI / 180;
        const Δλ = (coord2.longitude - coord1.longitude) * Math.PI / 180;

        const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
            Math.cos(φ1) * Math.cos(φ2) *
            Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return R * c; // Meters
    };

    useEffect(() => {

        const address = getValues('address');
        if (address?.coordinateAddress && address?.coordinateAddress?.latitude && address?.coordinateAddress?.longitude) {
            setCoordinates({ latitude: address.coordinateAddress.latitude, longitude: address.coordinateAddress.longitude });
            setInitialSearchAddress(address?.nameAdress);
            setInitialSearchCoordinates({ latitude: address.coordinateAddress.latitude, longitude: address.coordinateAddress.longitude });
        }

        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v9',
            center: [-3.7038, 40.4168], // Center on Spain
            zoom: 4.5
        });

        const geocoder = new MapboxGeocoder({
            accessToken: accessToken,
            mapboxgl: mapboxgl,
        });
        map.current.addControl(geocoder);

        geocoder.on('result', (event) => {
            const { result } = event;
            setCoordinates({ latitude: result.center[1], longitude: result.center[0] });
            setInitialSearchAddress(result.place_name);
            setInitialSearchCoordinates({ latitude: result.center[1], longitude: result.center[0] });
            setValue('address.nameAdress', result.place_name);
            setValue('address.coordinateAddress', { latitude: result.center[1], longitude: result.center[0] });
            console.log(`Search Address: ${result.place_name}`);
        });

        map.current.on('click', handleMapClick);

        map.current.on('load', () => {
            map.current.resize();
        });

        // Intersection Observer to detect when the map is visible
        intersectionObserverRef.current = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    map.current.resize(); // this will trigger resize when component is visible
                }
            },
            { threshold: 0.5 } // this will be triggered when 50% of the component is visible
        );

        if (mapContainer.current) {
            intersectionObserverRef.current.observe(mapContainer.current);
        }

        // Cleanup function
        return () => {
            if (intersectionObserverRef.current && mapContainer.current) {
                intersectionObserverRef.current.unobserve(mapContainer.current);
            }
            if (map.current) {
                map.current.remove();
            }
        };
    }, []);

    useEffect(() => {
        if (markerRef.current) {
            markerRef.current.remove();
        }
        if (coordinates) {
            markerRef.current = new mapboxgl.Marker({
                draggable: true
            })
                .setLngLat([coordinates.longitude, coordinates.latitude])
                .addTo(map.current);

            markerRef.current.on('dragend', () => {
                const lngLat = markerRef.current.getLngLat();
                const distance = calculateDistance(initialSearchCoordinates, { latitude: lngLat.lat, longitude: lngLat.lng });
                if (distance <= 250) {
                    setCoordinates({ latitude: lngLat.lat, longitude: lngLat.lng });
                    console.log(`Dragged Coordinates: ${lngLat.lat}, ${lngLat.lng}`);
                } else {
                    // Move marker back to initial search coordinates if moved beyond 250 meters
                    markerRef.current.setLngLat([initialSearchCoordinates.longitude, initialSearchCoordinates.latitude]);
                    console.log('Marker moved more than 250 meters away. Please search again.');
                }
            });
        }
    }, [coordinates]);


    return (
        <motion.div
            initial={{ x: delta >= 0 ? '10%' : '-10%', opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            className={`h-100  d-flex flex-column ${isEditMode ? 'edit-mode-padding' : 'py-4 nightspot-detail-side-padding'}`}
            style={{ animation: isEditMode ? 'none' : '' }}
        >
            <div className='d-flex flex-fill flex-column'>
                {
                    !isEditMode &&
                    <div className='d-flex  justify-content-center mb-4'>
                        <span className='title text-black fs-4 '>{t('nightspot_details_module.nightspot_address')}</span>
                    </div>
                }
                <div className='d-flex h-75 flex-column'>
                    <div className={`d-flex h-75 rounded mb-2 position-relative map-container`}>
                        <div ref={mapContainer} style={{ width: '100%', height: '100%', borderRadius: 10 }} />
                        <input disabled placeholder='Address' className='ps-3 rounded border-0 shadow-sm position-absolute' style={{ minHeight: '2rem', top: 10, left: '2%' }} value={initialSearchAddress} readOnly />
                    </div>
                    <div className='rounded border border-1 align-items-center justify-content-center d-flex fw-bold fs-6' style={{ width: 160, height: 35 }}>
                        <input
                            type="number"
                            placeholder='lat'
                            name="latitude"
                            value={coordinates ? coordinates.latitude.toFixed(4) : ''}
                            onChange={handleCoordinateChange}
                            style={{ width: '70px', marginRight: '10px' }}
                            className="border-0 text-secondary text-desc  hide-arrows"
                            disabled
                        />
                        <input
                            type="number"
                            name="longitude"
                            placeholder='lon'
                            value={coordinates ? coordinates.longitude.toFixed(4) : ''}
                            onChange={handleCoordinateChange}
                            style={{ width: '70px' }}
                            className="border-0 text-secondary text-desc hide-arrows"
                            disabled
                        />

                    </div>
                </div>
                <div className=''>
                    <span className='text-secondary text-desc'>{t('nightspot_details_module.search_for_nightspot_address')}</span>
                </div>
            </div>

            {
                !isMediumOrSmallScreen
                    ?
                    isEditMode ?
                        <div className="mt-2">
                            <button onClick={() => editModeSaveHandler(3)} className='edit-mode-save-btn'>{t('save')}</button>
                        </div>
                        :
                        <div className='d-flex flex-row justify-content-between'>
                            <SecondaryButton title={t('previous')} onClick={prevSlide} />
                            <PrimaryButton type='submit' title={t('next')} onClick={nextSlide} />
                        </div>
                    :
                    <></>
            }
        </motion.div>
    )
}

export default NightspotAddress;
