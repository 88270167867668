import React from 'react'
import PageNotFound from '../page-not-found/PageNotFound'
import { useTranslation } from 'react-i18next';

const FrizdaAds = () => {
    const { t } = useTranslation();
    return (
        <div className='w-100 h-100 p-4' style={{ backgroundColor: "#FFFFFF", borderRadius: "20px" }}>
            <PageNotFound title={t('screen_on_the_way', { screen: t('frizda_ads')})} />
        </div>
    )
}

export default FrizdaAds