import React from 'react'
import PageNotFound from '../page-not-found/PageNotFound'
import { useTranslation } from 'react-i18next';

const ClientData = () => {
    const { t } = useTranslation();
    return (
        <div className='w-100 h-100 p-4' style={{ backgroundColor: "#FFFFFF", borderRadius: "20px" }}>
            <PageNotFound title={t('unlock_feature_soon', { feature: t('client_data') })} tagLine={t('screen_on_the_way', { screen: t('client_data') })} />
        </div>
    )
}

export default ClientData