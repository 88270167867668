import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaArrowLeftLong, FaPencil } from "react-icons/fa6";
import { GoArrowUpRight } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import "./affiliate-marketing.css";

const AffiliateDetails = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className="h-100 w-100 overflow-y-auto affiliate-dashboard-container">
            <div className="w-100 d-flex justify-content-between align-items-center mb-3">
                <FaArrowLeftLong
                    size={20}
                    cursor="pointer"
                    color="#7A38FE"
                    onClick={() => navigate(-1)}
                />
            </div>

            <div className="affiliate-detail-widget-container">
                <Row>
                    <Col xs={12} sm={12} md={6}>
                        <Card>
                            <div className="w-100 h-100 d-flex justify-content-between align-items-start">
                                <div className="w-50">
                                    <p className="affiliate-widget-title">
                                        {t(
                                            "affiliate_marketing_module.affiliate_name"
                                        )}
                                    </p>
                                    <h6
                                        className="affiliate-widget-value"
                                        style={{ fontSize: "24px" }}
                                    >
                                        username_1
                                    </h6>
                                </div>
                                <div className="w-50">
                                    <p className="affiliate-widget-title">
                                        {t("email")}
                                    </p>
                                    <h6
                                        className="affiliate-widget-value"
                                        style={{ fontSize: "24px" }}
                                    >
                                        random@gmail.com
                                    </h6>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={6} sm={2} md={2}>
                        <Card>
                            <p className="affiliate-widget-title">
                                {t(
                                    "affiliate_marketing_module.affiliate_income"
                                )}
                            </p>
                            <h6 className="affiliate-widget-value">€ 2,500</h6>
                            <div className="affiliate-widget-extra text-success">
                                <GoArrowUpRight size={20} /> 20%
                            </div>
                        </Card>
                    </Col>
                    <Col xs={6} sm={2} md={2}>
                        <Card>
                            <p className="affiliate-widget-title">
                                {t("affiliate_marketing_module.leads")}
                            </p>
                            <h6 className="affiliate-widget-value">2,343</h6>
                            <div className="affiliate-widget-extra text-success">
                                <GoArrowUpRight size={20} /> 10% Today
                            </div>
                        </Card>
                    </Col>
                    <Col xs={12} sm={2} md={2}>
                        <Card>
                            <p className="affiliate-widget-title">
                                {t(
                                    "affiliate_marketing_module.commission_rate"
                                )}
                            </p>
                            <h6 className="affiliate-widget-value">16%</h6>
                            <div className="d-flex justify-content-end align-items-center">
                                <FaPencil size={15} cursor="pointer" />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>

            <Card className="data-table-card">
                <p className="data-table-header">
                    {t("affiliate_marketing_module.leads")}
                </p>
                <div className="data-table-wrapper">
                    <DataTable
                        value={[
                            {
                                name: "John Doe",
                                email: "johndoe@me.com",
                                purchaseDate: "01/01/2020",
                                source: "Website",
                                purchaseAmount: "100",
                            },
                        ]}
                        className={`responsive-table affiliate-details-data-table ${
                            [].length === 0 ? "empty-data-table" : ""
                        }`}
                        resizableColumns={false}
                        emptyMessage={t("no_data_found")}
                        readOnly
                    >
                        <Column
                            field="name"
                            headerClassName="name-col"
                            header={t("affiliate_marketing_module.lead_name")}
                        ></Column>
                        <Column
                            field="email"
                            headerClassName="email-col"
                            header={t("email")}
                            align="center"
                        ></Column>
                        <Column
                            field="purchaseDate"
                            headerClassName=""
                            header={t(
                                "affiliate_marketing_module.purchase_date"
                            )}
                            align="center"
                        ></Column>
                        <Column
                            field="source"
                            header={t("affiliate_marketing_module.source")}
                            align="center"
                            className="text-success"
                        ></Column>
                        <Column
                            field="purchaseAmount"
                            headerClassName=""
                            header={t(
                                "affiliate_marketing_module.purchase_amount"
                            )}
                            align="center"
                            body={(rowData) => (
                                <div className="text-success">
                                    € {rowData.purchaseAmount}
                                </div>
                            )}
                        ></Column>
                    </DataTable>
                    {/* {hasNext && (
                <div className="data-table-footer">
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={loadMoreData}
                    >
                        {t("show_more")} <FaChevronDown />
                    </span>
                </div>
            )} */}
                </div>
            </Card>
        </div>
    );
};

export default AffiliateDetails;
