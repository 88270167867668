import { useState } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import "./modal.css"

import modalimage from "../../styling/imgs/modal-img.png"

function ModalComponent() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <Button variant="primary" onClick={handleShow}>
        Modal Btn
      </Button>

      <Modal className='instruction-modal' show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          {/* <form className='modal-add-image'>
                <label htmlFor="imageinput">
                    <p className='modal-add-image-plusSign'>+</p>
                    <p className='modal-add-image-plusSign'>Add Image</p>
                </label>
               <input type="file" accept='image' id='imageinput' />
            
            </form> */}
          <img src={modalimage} alt="modal-img" className='modal-img' />
          <p className='modal-para'>To access pricing information, please Sign Up for an account. Signing up is quick and
            easy, and it gives you access to all our features and pricing details. Take the first step
            towards discovering our offerings by creating your account now.</p>
          <button className='modal-signup-btn'>Sign Up</button>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ModalComponent;