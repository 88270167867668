import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../redux/authslice';
import { ApiGet } from '../utils/ApiData';
import { Navigate, useNavigate } from 'react-router-dom';
import SpinLoader from '../components/home-page/loaders/SpinLoader';

const ProtectRoutes = ({ allowedRoles, children }) => {
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);

    const validateCookieUser = async () => {
        try {
            const user = await ApiGet("user/");
            if (user) {
                dispatch(authActions.login(user?.data))
            }
            else {
                dispatch(authActions.logout());
                // return navigate("/business/login", { replace: true });
            }
        } catch (error) {
            console.error(error);
            return navigate("/business/login", { replace: true });
        }
        setIsLoading(false);
    };

    useEffect(() => {
        validateCookieUser();
    }, []);

    // if (allowedRoles && !allowedRoles.includes(user.role)) {
    //     return <div className='text-dark'>404 Not Found</div>;
    // }
    return isLoading
        ?
        <div className='w-100' style={{ height: '100vh' }}>
            <SpinLoader />
        </div>
        :
        children;
};

export default ProtectRoutes