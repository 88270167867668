import { Editor } from "primereact/editor";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FaPencil } from "react-icons/fa6";

const LegalTab = () => {
    const [policyText, setPolicyText] = useState();
    const [isEditMode, setIsEditMode] = useState(false);

    return (
        <div className="privacy-policy-tab">
            <Row className="position-relative">
                <Col xs={12} md={12}>
                    <h3>Legal</h3>
                </Col>
                {!isEditMode ? (
                    <div
                        className="edit-privacy-policy-icon"
                        onClick={() => setIsEditMode(true)}
                    >
                        <FaPencil size={20} color="#7A38FE" />
                    </div>
                ) : (
                    <button
                        className="edit-privacy-policy-icon add-card-readers-btn"
                        type="submit"
                        onClick={() => setIsEditMode(false)}
                    >
                        Save
                    </button>
                )}
            </Row>

            {isEditMode ? (
                <div className="">
                    <Editor
                        value={policyText}
                        onTextChange={(e) => setPolicyText(e.htmlValue)}
                        style={{ height: "320px" }}
                    />
                </div>
            ) : (
                <div
                    style={{ color: "#000000" }}
                    dangerouslySetInnerHTML={{ __html: policyText }}
                ></div>
            )}
        </div>
    );
};

export default LegalTab;
