import React from 'react'
import {
    step1,
    step2,
    step3,
    step4,
    stepCounts,
    responsiveStepCounts
} from '../../assets/images'
import './styles/FrizdaHelp.css'
import { Trans, useTranslation } from 'react-i18next'

const FrizdaHelp = () => {

    const { t } = useTranslation();

    const STEPS = [
        {
            id: 1,
            imgUrl: step1,
            name: 'step1',
            description: 'step1_desc'
        },
        {
            id: 2,
            imgUrl: step2,
            name: 'step2',
            description: 'step2_desc'
        },
        {
            id: 3,
            imgUrl: step3,
            name: 'step3',
            description: 'step3_desc'
        },
        {
            id: 4,
            imgUrl: step4,
            name: 'step4',
            description: 'step4_desc'
        },
    ]

    return (
        <div className='help-section'>
            <div className="help-header">
                <Trans i18nKey="landing_page.how_frizda_works">
                    How <span>Frizda</span> Works
                </Trans>
            </div>
            <div className="step-wrapper">
                <div className="step-count-wrapper">
                    <img src={stepCounts} alt="Steps" className='step-count-img' />
                    <img src={responsiveStepCounts} alt="Steps" className='responsive-step-count-img' />
                </div>
                <div className="steps-wrapper">
                    {
                        STEPS.map(({ id, imgUrl, name, description }) => (
                            <div className="step-card" key={id}>
                                <p className='step-name'>{t(`landing_page.help_items.${name}`)}</p>
                                <div className="step-description">
                                    <p>{t(`landing_page.help_items.${description}`)}</p>
                                </div>
                                <div className="step-card-img-wrapper">
                                    <img src={imgUrl} alt={t(`landing_page.help_items.${name}`)} style={{ width: "100%", height: "100%", objectFit: "fill" }} />
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default FrizdaHelp