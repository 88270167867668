import { ArcElement, Chart } from 'chart.js';
import moment from 'moment';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { Card, Col, Modal, Row } from 'react-bootstrap';
import { Doughnut, Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { FaChevronDown } from 'react-icons/fa';
import { FaArrowRight } from 'react-icons/fa6';
import { IoIosCloseCircle } from "react-icons/io";
import { useSelector } from 'react-redux';
import {
    ingenico,
    redsys,
    square,
    stripe,
    sumup,
    verifone,
    zettle
} from '../../../assets/images';
import SpinLoader from '../../../components/home-page/loaders/SpinLoader';
import { ApiGet } from '../../../utils/ApiData';
import { DATA_PER_PAGE } from '../../../utils/constants';
import PrimaryButton from '../components/PrimaryButton';
import './monetics.css';

Chart.register(ArcElement);

// to set doughnut chart configuration
const doughnutChartOptions = {
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            callbacks: {
                label: function (context) {
                    return `€ ${context.raw}`;
                }
            },
            titleFont: {
                family: 'DM Sans',
                size: 12,
                weight: 'normal',
            },
            bodyFont: {
                family: 'DM Sans',
                size: 12,
                weight: 'normal',
                lineHeight: 1.2,
            },
            displayColors: false,
            backgroundColor: '#585858',
            titleColor: '#ffffff',
            bodyColor: '#ffffff',
            padding: 10,
            cornerRadius: 4,
        },
    },
    cutout: '60%', // value to set thickness of doughnut ring
};

// to set line chart configuration
const lineChartoptions = {
    maintainAspectRatio: false,
    scales: {
        x: {
            grid: {
                borderDash: [5, 5],
            },
        },
        y: {
            grid: {
                display: false,
                drawBorder: false,
            },
            ticks: {
                stepSize: 500, // size of jump on the y axis
            },
            beginAtZero: true,
        },
    },
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            callbacks: {
                title: function (context) {
                    return context[0].label;
                },
                label: function (context) {
                    return `Revenue: € ${context.raw}`;
                }
            },
            titleFont: {
                family: 'DM Sans',
                size: 12,
                weight: 'normal',
            },
            bodyFont: {
                family: 'DM Sans',
                size: 12,
                weight: 'normal',
                lineHeight: 1.2,
            },
            displayColors: false,
            backgroundColor: '#585858',
            titleColor: '#ffffff',
            bodyColor: '#ffffff',
            padding: 10,
            cornerRadius: 4,
            titleAlign: 'center',
            bodyAlign: 'center',
        },
    },
};

// card reader data
const CardReaders = [
    {
        id: 1,
        code: 'stripe',
        name: 'Stripe',
        image: stripe,
    },
    {
        id: 2,
        code: 'sumup',
        name: 'SumUp',
        image: sumup,
    },
    {
        id: 3,
        code: 'square',
        name: 'Square',
        image: square,
    },
    {
        id: 4,
        code: 'redsys',
        name: 'Redsys',
        image: redsys,
    },
    {
        id: 5,
        code: 'ingenico',
        name: 'Ingenico',
        image: ingenico,
    },
    {
        id: 6,
        code: 'verifone',
        name: 'Verifone',
        image: verifone,
    },
    {
        id: 7,
        code: 'zettle',
        name: 'Zettle',
        image: zettle,
    },
];

const MoneticsHome = () => {

    const { user } = useSelector((state) => state.auth);
    const { t } = useTranslation();

    const [doughnutChartData, setDoughnutChartData] = useState({
        labels: [],
        datasets: [
            {
                data: [],
                backgroundColor: ['#7A38FE', '#8E56FE'],
                hoverBackgroundColor: ['#7A38FE', '#8E56FE'],
                borderWidth: 0,
            },
        ],
    });
    const [lineChartData, setLineChartData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Revenue',
                data: [],
                borderColor: '#7A38FE',
                pointBackgroundColor: '#7A38FE',
                pointBorderColor: '#7A38FE',
                pointHoverBackgroundColor: '#7A38FE',
                pointHoverBorderColor: '#7A38FE',
                fill: false,
            },
        ],
    })
    const [legendData, setLegendData] = useState([]);
    const [showRedeemModal, setShowRedeemModal] = useState(false);
    const [showCardReaderModal, setShowCardReaderModal] = useState(false);
    const [selectedCardReader, setSelectedCardReader] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState({ startDate: null, endDate: null });
    const [currentPage, setCurrentPage] = useState(1);
    const [hasNext, setHasNext] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // We'll write api call here to fetch chart data
    const getChartData = () => {
        // dummy data
        const data = [
            { "label": t('cards'), "value": 30 },
            { "label": t('frizda_coins'), "value": 20 },
            { "label": t('tokens'), "value": 35 }
        ];

        const labels = data.map(item => item.label);
        const values = data.map(item => item.value);

        setDoughnutChartData({
            labels: labels,
            datasets: [
                {
                    data: values,
                    backgroundColor: ['#7A38FE', '#8E56FE', '#B692FE', '#D1A8FE'],
                    hoverBackgroundColor: ['#7A38FE', '#8E56FE', '#B692FE', '#D1A8FE'],
                    borderWidth: 0,
                },
            ]
        });

        setLegendData(data);
    }

    const loadMoreData = () => {
        if (!hasNext) {
            return;
        }
        setCurrentPage(currentPage + 1);
    };

    const handleSelectChange = (event) => {
        const value = event.target.value;
        let newFilterValue = { startDate: null, endDate: null };

        switch (value) {
            case 'lastMonth':
                newFilterValue = {
                    startDate: moment().subtract(1, 'months').startOf('month'),
                    endDate: moment().subtract(1, 'months').endOf('month')
                };
                break;
            case 'today':
                newFilterValue = {
                    startDate: moment().startOf('day'),
                    endDate: moment().endOf('day')
                };
                break;
            case 'yesterday':
                newFilterValue = {
                    startDate: moment().subtract(1, 'days').startOf('day'),
                    endDate: moment().subtract(1, 'days').endOf('day')
                };
                break;
            case 'thisWeek':
                newFilterValue = {
                    startDate: moment().startOf('week'),
                    endDate: moment().endOf('week')
                };
                break;
            case 'thisMonth':
                newFilterValue = {
                    startDate: moment().startOf('month'),
                    endDate: moment().endOf('month')
                };
                break;
            default:
                break;
        }

        setSelectedFilter(newFilterValue);
    };

    const getRevenueReportData = async () => {
        setIsLoading(true);
        try {
            const { data } = await ApiGet(`monetics/get-revenue-report?id=${user?.nightSpotId}&startDate=${selectedFilter.startDate}&endDate=${selectedFilter.endDate}`);

            if (data) {
                console.log(data);

                setLineChartData({
                    ...lineChartData,
                    labels: data?.labels,
                    datasets: [
                        {
                            ...lineChartData.datasets[0],
                            data: data?.revenue
                        }
                    ]
                });
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error(error);
        }
    }

    const getTransactions = async () => {
        setIsLoading(true);
        try {
            const { data, metadata } = await ApiGet(`monetics/get-all-by-nightspot?id=${user?.nightSpotId}&startDate=${selectedFilter.startDate}&endDate=${selectedFilter.endDate}&page=${currentPage}&limit=${DATA_PER_PAGE}`);
            if (data) {
                console.log(data);
                setTableData(...tableData, ...data);
            }
            if (metadata?.hasNext) {
                setHasNext(true);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error(error);
        }
    }

    const getTotalIncome = async () => {
        setIsLoading(true);
        try {
            const { data } = await ApiGet(`monetics/get-total-income?id=${user?.nightSpotId}&startDate=${selectedFilter.startDate}&endDate=${selectedFilter.endDate}`);
            if (data) {
                console.log(data);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error(error);
        }
    }

    useEffect(() => {
        getTransactions();
    }, [selectedFilter, currentPage]);

    useEffect(() => {
        getTotalIncome();
        getRevenueReportData();
    }, [selectedFilter]);

    useEffect(() => {
        // dummy data
        getChartData();
    }, []);

    return (
        <>{
            isLoading
                ?
                <SpinLoader />
                :
                <div className='h-100 overflow-y-auto monetics-home-container'>
                    <Row className='widget-row'>
                        <Col xs={12} md={4} lg={4} className='chart-widget-col'>
                            <div className="dashboard-widget-card">
                                <div className="d-flex justify-content-between align-items-center">
                                    <p className='dashboard-widget-title'>{t('total_income')}</p>
                                    <div className="select-container">
                                        <select className='filter-select' onChange={handleSelectChange}>
                                            <option value="lastMonth" defaultValue='lastMonth'>{t('last_month')}</option>
                                            <option value="today">{t('today')}</option>
                                            <option value="thisYear">{t('yesterday')}</option>
                                            <option value="thisWeek">{t('this_week')}</option>
                                            <option value="thisMonth">{t('this_month')}</option>
                                        </select>
                                        <FaChevronDown className="custom-arrow" />
                                    </div>
                                </div>
                                <div className="d-flex h-100 gap-1">
                                    <div className="income-value">
                                        € 2500
                                    </div>
                                    <div className="w-50 d-flex justify-content-center align-items-center">
                                        <div className="income-chart-container">
                                            <Doughnut data={doughnutChartData} options={doughnutChartOptions} />
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-wrap justify-content-center gap-3 mt-2">
                                    {legendData.map((item, index) => (
                                        <div key={index} className='doughnut-legend'>
                                            <div style={{ backgroundColor: doughnutChartData.datasets[0].backgroundColor[index], width: '10px', height: '10px', borderRadius: '50%', marginRight: '5px' }}></div>
                                            <span>{item.label}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <div className="dashboard-widget-card justify-content-between">
                                {
                                    false
                                        ?
                                        <>
                                            <p className='dashboard-widget-title'>Redeemable Points</p>
                                            <div className="avaliable-points-container">
                                                <p className='avaliable-points'>121</p>
                                                <div className="d-flex flex-column justify-content-center">
                                                    <div className="fs-4 fw-semibold">Frizda Coins</div>
                                                    <span>Available</span>
                                                </div>
                                            </div>
                                            <PrimaryButton title='Redeem Now' mode='full' customBtnStyle='rounded-pill redeem-btn' onClick={() => setShowRedeemModal(true)} />
                                        </>
                                        :
                                        <div className="widget-title w-100 h-100 d-flex justify-content-center align-items-center fs-3 fw-semibold">
                                            {t('coming_soon')}
                                        </div>
                                }
                            </div>
                        </Col>
                        <Col xs={12} md={4} lg={4} className='cards-widget-col'>
                            <div className="dashboard-widget-card" style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
                                <div className="h-100 d-flex flex-column justify-content-between">
                                    <div className="text-white text-center mt-4">Card Readers{selectedCardReader.length > 0 ? ': CONNECTED' : ' are NOT CONNECTED'}</div>
                                    {
                                        selectedCardReader.length > 0 ?
                                            <div className="card-reader-scrollable-wrapper">
                                                <div className="d-flex align-items-center gap-3">
                                                    {
                                                        selectedCardReader.map((reader, index) => (
                                                            <div key={index} className="selected-card-reader-img-wrapper">
                                                                <img src={reader.image} alt={reader.name} style={{ width: '100%', objectFit: 'contain' }} />
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                            :
                                            <></>
                                    }
                                    <PrimaryButton title={t('connect')} mode='full' customBtnStyle='card-connect-btn rounded-pill' onClick={() => setShowCardReaderModal(true)} />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Card className='data-table-card pb-3' style={{ height: '400px' }}>
                        <div className="">
                            <p className='data-table-header'>{t('revenue_report')}</p>
                        </div>
                        <div className='line-chart-wrapper'>
                            <div className="line-chart-container">
                                <Line data={lineChartData} options={lineChartoptions} />
                            </div>
                        </div>
                    </Card>

                    <Card className='data-table-card'>
                        <p className='data-table-header'>{t('transactions')}</p>
                        <div className="data-table-wrapper">
                            <DataTable value={tableData} className='monetics-responsive-table'>
                                <Column field="transactionId" bodyClassName='transaction-id-col' body={(rowData) => `#${rowData.transactionId}`}></Column>
                                <Column field="name" bodyClassName='name-col'></Column>
                                <Column field="date" align='center' bodyClassName='date-col'></Column>
                                <Column field="time" align='center' bodyClassName='time-col'></Column>
                                <Column field="status" align='center' bodyClassName={(rowData) => `status-col ${rowData?.status.toUpperCase() == 'COMPLETED' ? 'completed-status' : 'pending-status'}`}></Column>
                                <Column field="amount" align='center' bodyClassName='amount-col' body={(rowData) => `€ ${rowData.amount}`}></Column>
                            </DataTable>
                            {
                                hasNext &&
                                <div className="data-table-footer">
                                    <span style={{ cursor: 'pointer' }} onClick={loadMoreData}>
                                        {t('show_more')} <FaChevronDown />
                                    </span>
                                </div>
                            }
                        </div>
                    </Card>
                    <RedeemPointModal showRedeemModal={showRedeemModal} setShowRedeemModal={setShowRedeemModal} />
                    <CardReaderModal showCardReaderModal={showCardReaderModal} setShowCardReaderModal={setShowCardReaderModal} setSelectedCardReader={setSelectedCardReader} selectedCardReader={selectedCardReader} />
                </div>
        }
        </>
    )
}

export default MoneticsHome;




const RedeemPointModal = ({ showRedeemModal, setShowRedeemModal }) => {
    return (
        <Modal
            show={showRedeemModal}
            onHide={setShowRedeemModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='redeem-point-modal'
        >
            <Modal.Body>
                <p className='redeem-modal-title'>Redeem Frizda Coins</p>
                <div className="redeem-point-wrapper">
                    <p className='avaliable-points'>1,210</p>
                    <p className='fs-5' style={{ color: '#5A5A5A', fontWeight: '500' }}>Available Frizda coins</p>
                </div>
                <span className='text-left' style={{ margin: '20px 0', color: '#838383', fontSize: '14px', textAlign: 'left' }}>convert your Frizda coins to wallet money</span>
                <div className="redeem-amount-wrapper">
                    <div className="redeem-amount">
                        € 1,200
                    </div>
                    <div className='redeem-amount-btn'>
                        <span>Redeem Coins</span> <FaArrowRight size={20} />
                    </div>
                </div>
                <div className="position-absolute top-0 end-0 d-flex justify-content-center align-items-center cursor-pointer" onClick={() => setShowRedeemModal(false)}>
                    <IoIosCloseCircle size={25} color='#7A38FE' />
                </div>
            </Modal.Body>
        </Modal >
    )
}



const CardReaderModal = ({ showCardReaderModal, setShowCardReaderModal, setSelectedCardReader, selectedCardReader }) => {
    const { t } = useTranslation();
    const [tempSelectedReaders, setTempSelectedReaders] = useState([...selectedCardReader]);

    const handleSelectCardReader = (reader) => {
        const isSelected = tempSelectedReaders.some(selected => selected.id === reader.id);
        if (isSelected) {
            setTempSelectedReaders(tempSelectedReaders.filter(selected => selected.id !== reader.id));
        } else {
            setTempSelectedReaders([...tempSelectedReaders, reader]);
        }
    }

    const handleNextClick = () => {
        setSelectedCardReader(tempSelectedReaders);
        setShowCardReaderModal(false);
    }

    return (
        <Modal
            show={showCardReaderModal}
            onHide={setShowCardReaderModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='card-reader-modal'
        >
            <Modal.Body>
                <p className='redeem-modal-title'>Select the brand of Card Readers</p>

                <div className="card-reader-wrapper">
                    {CardReaders.map(reader => (
                        <div
                            key={reader.id}
                            className={`card-reader-img-wrapper ${tempSelectedReaders.some(selected => selected.id === reader.id) ? 'selected-reader' : ''}`}
                            onClick={() => handleSelectCardReader(reader)}
                        >
                            <img src={reader.image} alt={reader.name} />
                        </div>
                    ))}
                </div>

                <div className='redeem-amount-btn w-100 position-relative justify-content-center' onClick={handleNextClick}>
                    <span>{t('next')}</span> <FaArrowRight className='position-absolute' style={{ top: '50%', right: '20px', transform: 'translateY(-50%)' }} size={20} />
                </div>

                <div className="position-absolute top-0 end-0 d-flex justify-content-center align-items-center cursor-pointer" onClick={() => setShowCardReaderModal(false)}>
                    <IoIosCloseCircle size={25} color='#7A38FE' />
                </div>
            </Modal.Body>
        </Modal >
    )
}
